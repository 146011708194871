import React from "react";

type ButtonVariants = "text" | "filled" | "outlined";
type ButtonSizes = "small" | "normal" | "large";

export interface IButton {
  type?: any;
  children: string | React.ReactNode;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  full?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  tabIndex?: number;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<IButton> = ({
  type = "button",
  variant = "filled",
  size = "normal",
  full = false,
  children,
  onClick,
  tabIndex,
  className,
  disabled,
}) => {
  const typestyles: string =
    variant === "text"
      ? "border-transparent text-dorothy-orange hover:bg-dorothy-orange/10"
      : variant === "filled"
      ? "border-transparent text-white bg-dorothy-orange shadow-sm hover:bg-dorothy-dark-orange"
      : "border-dorothy-orange text-dorothy-orange shadow-sm hover:bg-dorothy-orange/10";
  const sizeStyles: string =
    size === "small"
      ? "px-3 py-1 text-xs"
      : size === "large"
      ? "px-7 py-3 text-base"
      : "px-5 py-2 text-sm";
  const displayStyles: string = full ? "w-full justify-center" : "";
  return (
    <button
      onClick={onClick}
      type={type}
      tabIndex={tabIndex}
      className={`whitespace-nowrap font-normal inline-flex items-center rounded-lg border focus:outline-none focus:ring-2 focus:ring-dark-blue focus:ring-offset-2 disabled:bg-gray-400 ${sizeStyles} ${typestyles} ${displayStyles} ${
        className || ""
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default Button;
