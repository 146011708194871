import { NavLink, useLocation } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { useCustomerData } from "../hooks/useCustomerData";
import React, { useEffect } from "react";
import { useCustomerContext } from "../contexts/CustomerContext";
import { comboPackagesList, packages } from "../constants/packages";
import { routes } from "../constants/routes";

const Menu = ({ onClose, onLogout }: { onClose?: any; onLogout?: any }) => {
  const askDotUrl = process.env.REACT_APP_ASKDOT_URL || "";
  const { pathname } = useLocation();
  const { feature, setFeature } = useCustomerContext();
  const { activeSubscription } = useCustomerData();
  const PeopleAI = (
    <NavLink
      to={routes.peopleai}
      className={({ isActive }) =>
        isActive ? "menu-link-active" : "menu-link"
      }
      onClick={onClose}
    >
      <span className="mr-3 h-8 w-8 flex-shrink-0 people">
        <People />
      </span>
      People+AI
    </NavLink>
  );

  const Crosscite = (
    <NavLink
      to={routes.crosscite}
      className={({ isActive }) =>
        isActive ? "menu-link-active" : "menu-link"
      }
      onClick={onClose}
    >
      <img
        alt="Crosscite"
        src="/crosscite.png"
        className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
      />
      CrossCite
    </NavLink>
  );

  const AskDot = (
    <a href={askDotUrl} className="menu-link" target="_blank" rel="noreferrer">
      <img
        alt="Askdot"
        src="/askdot.ico"
        className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
      />
      AskDot
    </a>
  );

  useEffect(() => {
    if (activeSubscription) {
      const activePackageName = activeSubscription?.items?.[0]?.product?.name;
      setFeature(activePackageName);
    }
  }, [activeSubscription]);

  const showFeatures: JSX.Element[] = [];

  switch (feature) {
    case comboPackagesList.ultimate:
      showFeatures.push(PeopleAI, Crosscite, AskDot);
      break;
    case comboPackagesList.premium:
      showFeatures.push(Crosscite, AskDot);
      break;
    case comboPackagesList.foundation:
    case packages.askdot:
      showFeatures.push(AskDot);
      break;
    default:
      break;
  }

  return (
    <>
      <NavLink
        to={routes.projects}
        className={({ isActive }) =>
          isActive && pathname === routes.projects
            ? "menu-link-active"
            : "menu-link"
        }
        onClick={onClose}
      >
        <ClipboardDocumentListIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Projects
      </NavLink>

      {showFeatures.map((feature: JSX.Element, index: number) => (
        <React.Fragment key={index}>{feature}</React.Fragment>
      ))}

      <NavLink
        to={routes.help}
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <QuestionMarkCircleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Help
      </NavLink>
      <span className="menu-link cursor-pointer" onClick={onLogout}>
        <ArrowRightOnRectangleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Logout
      </span>
    </>
  );
};

export default Menu;
