import React, { useEffect, useState } from "react";
import cx from "classnames";
import Checkbox from "../../components/shared/checkbox/Checkbox";
import SubscriptionCard from "../../components/shared/subscriptioncard";
import {
  allPackages,
  comboPackagesList,
  packageBundles,
  packages,
} from "../../constants/packages";
import { useCustomerContext } from "../../contexts/CustomerContext";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/shared";
import { useCustomerData } from "../../hooks/useCustomerData";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { routes } from "../../constants/routes";
import { subscriptionIntervals } from "../../constants/subscriptions";

interface ISubscriptionCard {
  id: number;
  title: string;
  type: packageBundles.introductory | packageBundles.combo;
  disabled: boolean;
  packages: string[];
}

const Subscriptions: React.FC = () => {
  const navigate = useNavigate();
  const {
    introductoryPackages,
    activeSubItem,
    comboPackages,
    subscriptionsLoading,
    disabledSubItems,
  } = useSubscriptions();

  const {
    cancelSubscription,
    activeSubscription,
    cancelSubscriptionLoading,
    scheduledSubscription,
    refetchCustomerSubscriptions,
    refetchScheduledSubscriptions,
    scheduledSubscriptionLoading,
    cancelScheduledSubscriptionLoading,
    handleCancelScheduledSubscription,
  } = useCustomerData();

  const [mode, setMode] = useState<boolean>(false);
  const { subscriptionMessage, setSelectedPackage } = useCustomerContext();
  const interval = mode
    ? subscriptionIntervals.year
    : subscriptionIntervals.month;

  useEffect(() => {
    if (activeSubItem) {
      setMode(
        activeSubItem.activeSubInterval === subscriptionIntervals.year
          ? true
          : false
      );
    }
  }, [activeSubItem]);

  const handleSubscriptionMode = (checked: boolean) => {
    setMode(checked);
  };

  const handleSelectedPackage = (pkg: any) => {
    sessionStorage.setItem("selectedPackage", JSON.stringify(pkg));
    setSelectedPackage(pkg);
    navigate(routes.pay);
  };

  const handleCancelSubscription = async () => {
    if (activeSubscription && activeSubscription.id) {
      try {
        await cancelSubscription(activeSubscription.id).unwrap();
        await Promise.allSettled([
          refetchScheduledSubscriptions(),
          refetchCustomerSubscriptions(),
        ]).then(() => sessionStorage.clear());
      } catch (error) {
        console.error("Failed to cancel subscription:", error);
      }
    }
  };

  if (
    subscriptionsLoading ||
    cancelSubscriptionLoading ||
    scheduledSubscriptionLoading ||
    cancelScheduledSubscriptionLoading
  ) {
    return <Loader />;
  }
  const introductoryCards: ISubscriptionCard[] = [
    {
      id: 1,
      title: packages.novelty,
      disabled: disabledSubItems?.includes(packages.novelty),
      type: packageBundles.introductory,
      packages: [packages.novelty],
    },
    {
      id: 2,
      title: packages.freedom,
      disabled: disabledSubItems?.includes(packages.freedom),
      type: packageBundles.introductory,
      packages: [packages.freedom],
    },
    {
      id: 3,
      title: packages.askdot,
      disabled: disabledSubItems?.includes(packages.askdot),
      type: packageBundles.introductory,
      packages: [packages.askdot],
    },
  ];
  const comboCards: ISubscriptionCard[] = [
    {
      id: 1,
      title: comboPackagesList.foundation,
      disabled: false,
      type: packageBundles.combo,
      packages: [packages.novelty, packages.freedom, packages.askdot],
    },
    {
      id: 2,
      title: comboPackagesList.premium,
      disabled: false,
      type: packageBundles.combo,
      packages: [
        packages.novelty,
        packages.freedom,
        packages.askdot,
        packages.crosscite,
      ],
    },
    {
      id: 3,
      title: comboPackagesList.ultimate,
      disabled: false,
      type: packageBundles.combo,
      packages: allPackages,
    },
  ];

  return (
    <div className="flex flex-col gap-y-8 pb-12 font-inter">
      <div className="flex flex-col gap-y-3">
        <i
          className={`${subscriptionMessage.icon} ${subscriptionMessage.color.text} text-5xl text-center`}
        ></i>
        <span
          className={`font-montserrat font-medium text-center text-[20px] ${subscriptionMessage.color.text}`}
        >
          {subscriptionMessage.message}
        </span>
      </div>
      <span className="text-dorothy-subscribe text-[32px] text-center font-extrabold">
        Choose The Right Plan for You
      </span>
      <div className="text-base font-light text-dorothy-subscribe self-center text-center max-w-2xl">
        We have several powerful plans to showcase your business and get
        discovered as a creative entrepreneurs. Everything you need.
      </div>
      <div className="flex gap-x-2 items-center px-4 py-1 font-extrabold bg-transparent text-dark-purple border border-dashed border-dark-purple max-w-3xl mx-auto rounded-lg">
        <i className="fas fa-certificate blink"></i> Unlock bigger savings with
        our annual plan, choose annual plan and enjoy minimum 30% discount!
      </div>
      <div className="flex justify-center items-center gap-x-4">
        <span
          className={cx("text-base text-dorothy-subscribe w-24", {
            "font-extrabold": !mode,
            "font-normal": mode,
          })}
        >
          Bill Monthly
        </span>
        <Checkbox onToggle={handleSubscriptionMode} initialValue={mode} />
        <span
          className={cx("text-base text-dorothy-subscribe w-28", {
            "font-extrabold": mode,
            "font-normal": !mode,
          })}
        >
          Bill Annually
        </span>
      </div>
      <div className="relative border-4 border-white rounded-2xl py-10 px-8 my-6 mx-auto">
        <span className="absolute -top-[18px] left-8 text-dorothy-subscribe font-extrabold text-2xl bg-dorothy-gray">
          Introductory packages
        </span>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-12 place-items-center mt-4">
          {introductoryCards.map((card: ISubscriptionCard) => (
            <React.Fragment key={card.id}>
              <SubscriptionCard
                handleCancelSubscription={handleCancelSubscription}
                cancelUpcomingSubscription={handleCancelScheduledSubscription}
                isCardDisabled={card.disabled}
                isUpcoming={
                  scheduledSubscription?.upcomingSubscription
                    ?.subscriptionName === card.title &&
                  scheduledSubscription?.upcomingSubscription?.interval ===
                    interval
                }
                activeSubscription={activeSubItem}
                mode={mode}
                title={card.title}
                packages={card.packages}
                type={card.type}
                introductoryPackages={introductoryPackages}
                comboPackages={comboPackages}
                handleSelectedPackage={handleSelectedPackage}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="relative border-4 border-white rounded-2xl py-10 px-8 my-6 mx-auto">
        <span className="absolute -top-[18px] left-8 text-dorothy-subscribe font-extrabold text-2xl bg-dorothy-gray">
          Combo packages
        </span>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-12 place-items-center mt-4">
          {comboCards.map((card: ISubscriptionCard) => (
            <React.Fragment key={card.id}>
              <SubscriptionCard
                handleCancelSubscription={handleCancelSubscription}
                cancelUpcomingSubscription={handleCancelScheduledSubscription}
                isCardDisabled={card.disabled}
                activeSubscription={activeSubItem}
                isUpcoming={
                  scheduledSubscription?.upcomingSubscription
                    ?.subscriptionName === card.title &&
                  scheduledSubscription?.upcomingSubscription?.interval ===
                    interval
                }
                mode={mode}
                title={card.title}
                packages={card.packages}
                type={card.type}
                introductoryPackages={introductoryPackages}
                comboPackages={comboPackages}
                handleSelectedPackage={handleSelectedPackage}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
