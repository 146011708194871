import React, { useEffect, useState } from "react";
import {
  allPackages,
  packageBundles,
  introductoryPackages as introPkgs,
  packageButton,
  comboPackages as comboPkgs,
  comboPackagesList,
} from "../../../constants/packages";
import cx from "classnames";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";
import { calculateSavings, getStripeDate } from "../../../utils/helpers";
import {
  subscriptionIntervals,
  subscriptionStatuses,
} from "../../../constants/subscriptions";
import Modal from "../modal/Modal";
import Button from "../button/Button";

interface SubscriptionCardProps {
  isCardDisabled: boolean;
  activeSubscription: any;
  isUpcoming: boolean;
  mode: boolean;
  title: string;
  packages: string[];
  type: packageBundles.introductory | packageBundles.combo;
  introductoryPackages: any;
  comboPackages: any;
  handleSelectedPackage: (pkg: any) => void;
  handleCancelSubscription: () => any;
  cancelUpcomingSubscription: () => any;
}

type ViewModalProps = {
  show: boolean;
  text: string;
};

type ButtonName =
  | packageButton.subscribe
  | packageButton.resubscribe
  | packageButton.upgrade
  | packageButton.downgrade
  | packageButton.cancel
  | packageButton.cancelUpcoming;

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  isCardDisabled,
  isUpcoming,
  activeSubscription,
  mode,
  title,
  packages,
  type,
  introductoryPackages,
  comboPackages,
  handleSelectedPackage,
  handleCancelSubscription,
  cancelUpcomingSubscription,
}) => {
  const [buttonName, setButtonName] = useState<ButtonName>(
    packageButton.subscribe
  );
  const [viewModal, setViewModal] = useState<ViewModalProps>({
    show: false,
    text: "",
  });
  const interval = mode
    ? subscriptionIntervals.year
    : subscriptionIntervals.month;
  const isAnnualCard = mode;
  const isCardActive =
    activeSubscription?.activeSubName === title &&
    activeSubscription?.activeSubInterval === interval;
  const willCancelAtPeriodEnd =
    activeSubscription?.cancel_at !== null &&
    activeSubscription?.canceled_at !== null &&
    activeSubscription?.cancel_at_period_end;

  const noSubscription = !isCardActive && !isCardDisabled;
  const choosePackage =
    type === packageBundles.introductory ? introductoryPackages : comboPackages;

  const totalSavings = calculateSavings(
    choosePackage?.[title]?.["monthly"]?.["price"],
    choosePackage?.[title]?.["annually"]?.["price"]
  );
  const getDetailsByPlan = (
    planMode: boolean,
    selectedPackage: any,
    field: string
  ) => {
    if (!planMode) return selectedPackage?.["monthly"]?.[field];
    else return selectedPackage?.["annually"]?.[field];
  };

  const getFieldValue = (field: string) => {
    return type === packageBundles.introductory
      ? getDetailsByPlan(mode, introductoryPackages?.[title], field)
      : getDetailsByPlan(mode, comboPackages?.[title], field);
  };

  const price = getFieldValue("price");
  const priceId = getFieldValue("priceId");
  const currency = getFieldValue("currency");
  const packageName = getFieldValue("name");

  const allowedPkgs: string[] = allPackages.filter((p) => packages.includes(p));

  const packageDetails = {
    name: packageName,
    amount: price,
    interval,
    priceId,
    currency,
  };

  const closeModal = () => {
    setViewModal({ show: false, text: "" });
  };

  const handleSubscription = () => {
    if (noSubscription) {
      handleSelectedPackage(packageDetails);
    }
    if (!isCardDisabled) {
      if (isCardActive) {
        if (!willCancelAtPeriodEnd) {
          handleCancelSubscription();
        } else {
          handleSelectedPackage(packageDetails);
        }
      }
    }
  };

  // const purchaseSubscription = () => {
  //   if (!isCardDisabled) {
  //     if (isCardActive) {
  //       if (!willCancelAtPeriodEnd) {
  //         handleCancelSubscription();
  //       } else {
  //         handleSelectedPackage(packageDetails);
  //       }
  //     } else {
  //       handleSelectedPackage(packageDetails);
  //     }
  //   }
  // };

  // const handleSubscription = () => {
  //   if (
  //     !activeSubscription ||
  //     activeSubscription.status !== subscriptionStatuses.active ||
  //     willCancelAtPeriodEnd
  //   ) {
  //     handleSelectedPackage(packageDetails);
  //   }

  //   if (!isCardDisabled) {
  //     if (isCardActive) {
  //       if (!willCancelAtPeriodEnd) {
  //         setViewModal({
  //           show: true,
  //           text: `You will have full access to your <strong>${
  //             activeSubscription.activeSubName
  //           }</strong> features until <strong>${getStripeDate(
  //             activeSubscription.current_period_end
  //           )}</strong>. Then your subscription will be canceled.`,
  //         });
  //       }
  //     } else {
  //       if (comboPkgs.includes(activeSubscription.activeSubName)) {
  //         setViewModal({
  //           show: true,
  //           text: `
  //             <div>Your current subscription
  //             <strong class="text-dark-purple">${activeSubscription.activeSubName}</strong>
  //             <sup class="bg-dark-purple px-2 rounded-lg font-extrabold text-white text-[9px]">${activeSubscription.activeSubInterval}ly</sup> will be canceled immediately, Your current subscription will be <strong class="text-dark-purple">${title}</strong>
  //             <sup class="bg-dark-purple px-2 rounded-lg font-extrabold text-white text-[9px]">${interval}ly</sup></div>
  //           `,
  //         });
  //       } else {
  //         setViewModal({
  //           show: true,
  //           text: `
  //             <div>Your current subscription
  //             <strong class="text-dark-purple">${activeSubscription.activeSubName}</strong>
  //             <sup class="bg-dark-purple px-2 rounded-lg font-extrabold text-white text-[9px]">${activeSubscription.activeSubInterval}ly</sup> will be canceled immediately, Your current subscription will be <strong class="text-dark-purple">${title}</strong>
  //             <sup class="bg-dark-purple px-2 rounded-lg font-extrabold text-white text-[9px]">${interval}ly</sup></div>
  //         `,
  //         });
  //       }
  //     }
  //   }
  // };
  useEffect(() => {
    if (activeSubscription) {
      if (!isCardDisabled) {
        if (
          introPkgs.includes(activeSubscription.activeSubName) &&
          type === packageBundles.combo &&
          !willCancelAtPeriodEnd
        ) {
          setButtonName(packageButton.upgrade);
        }
        if (
          comboPkgs.includes(activeSubscription.activeSubName) &&
          !willCancelAtPeriodEnd
        ) {
          switch (activeSubscription.activeSubName) {
            case comboPackagesList.foundation:
              if (
                title === comboPackagesList.premium ||
                title === comboPackagesList.ultimate
              ) {
                setButtonName(packageButton.upgrade);
              }
              break;
            case comboPackagesList.premium:
              if (title === comboPackagesList.ultimate) {
                setButtonName(packageButton.upgrade);
              }
              break;
          }
        }
        if (activeSubscription.activeSubName === title) {
          if (activeSubscription.activeSubInterval === interval) {
            if (!willCancelAtPeriodEnd) {
              setButtonName(packageButton.cancel);
            } else {
              setButtonName(packageButton.resubscribe);
            }
          } else {
            if (isAnnualCard && !willCancelAtPeriodEnd) {
              setButtonName(packageButton.upgrade);
            } else {
              setButtonName(packageButton.subscribe);
            }
          }
        }
      }
    } else {
      setButtonName(packageButton.subscribe);
    }
  }, [
    activeSubscription,
    title,
    interval,
    isCardDisabled,
    isAnnualCard,
    willCancelAtPeriodEnd,
    type,
    isUpcoming,
  ]);

  return (
    <>
      <div
        className={cx("w-[290px] h-[397px] rounded-3xl px-8 py-6 group", {
          "hover:bg-dorothy-subscribe hover:-translate-y-4 transition duration-200 cursor-pointer bg-white":
            noSubscription && !isUpcoming,
          "bg-dorothy-subscribe select-none": isCardActive && !isUpcoming,
          "bg-gray-200 !cursor-not-allowed select-none":
            isCardDisabled && !isUpcoming,
          "!cursor-default select-none bg-white hover:!translate-y-0 ring-2 ring-dorothy-orange":
            isUpcoming,
        })}
      >
        <div className="flex justify-between items-center group">
          <span
            className={cx("text-dorothy-subscribe font-extrabold text-2xl", {
              "group-hover:text-white": noSubscription && !isUpcoming,
              "text-white": isCardActive && !isUpcoming,
              "!text-gray-400": isCardDisabled && !isUpcoming,
            })}
          >
            {title}
          </span>
          {isAnnualCard && noSubscription && !isUpcoming && (
            <div className="hidden group-hover:flex justify-center items-center text-xs px-2 py-1 text-white bg-dark-purple font-extrabold rounded-[4px]">
              Save {totalSavings}%
            </div>
          )}

          {isUpcoming && !isCardActive && (
            <div className="relative bottom-3 left-4 flex justify-center items-center text-xs px-2 py-1 text-white bg-dorothy-orange font-extrabold rounded-full">
              Upcoming
            </div>
          )}

          {isCardActive && (
            <div className="relative bottom-3 left-4 flex justify-center items-center text-xs px-2 py-1 text-white bg-green-600 font-extrabold rounded-full">
              Active
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-2 mt-4">
          {allPackages.map((pkg: string, index: number) => {
            return (
              <div className="flex items-center gap-x-2" key={index}>
                <span
                  className={cx({
                    "opacity-40": !allowedPkgs.includes(pkg) && !isUpcoming,
                    "icon-white": noSubscription && !isUpcoming,
                    "white-icon": isCardActive && !isUpcoming,
                    "gray-icon": isCardDisabled && !isUpcoming,
                  })}
                >
                  {allowedPkgs.includes(pkg) ? <Check /> : <Cross />}
                </span>
                <span
                  className={cx("font-normal text-base", {
                    "text-dorothy-subscribe":
                      allowedPkgs.includes(pkg) && !isUpcoming,
                    "text-dorothy-subscribe opacity-30":
                      !allowedPkgs.includes(pkg) && !isUpcoming,
                    "group-hover:text-white": noSubscription && !isUpcoming,
                    "text-white": isCardActive && !isUpcoming,
                    "!text-gray-500": isCardDisabled && !isUpcoming,
                  })}
                >
                  {pkg}
                </span>
              </div>
            );
          })}
          <div className="mt-4">
            <span
              className={cx("font-normal text-base text-dorothy-subscribe", {
                "group-hover:text-white": noSubscription && !isUpcoming,
                "text-white": isCardActive && !isUpcoming,
                "!text-gray-500": isCardDisabled && !isUpcoming,
              })}
            >
              $
            </span>
            <span
              className={cx("text-2xl font-extrabold text-dorothy-subscribe", {
                "group-hover:text-white": noSubscription && !isUpcoming,
                "text-white": isCardActive && !isUpcoming,
                "!text-gray-500": isCardDisabled && !isUpcoming,
              })}
            >
              {price}
            </span>
            <span
              className={cx(
                "text-base font-normal text-dorothy-subscribe/70 ml-1",
                {
                  "group-hover:text-white": noSubscription && !isUpcoming,
                  "text-white": isCardActive && !isUpcoming,
                  "!text-gray-500": isCardDisabled && !isUpcoming,
                }
              )}
            >
              /{interval}
            </span>
          </div>
          <div className="flex gap-x-2 items-center">
            {!isUpcoming && (
              <button
                className={cx(
                  "flex justify-center items-center border-0 p-3 w-full bg-light-purple rounded-lg text-dark-purple text-base font-extrabold mt-2 hover:scale-110 transition ease-in-out",
                  {
                    "text-red-400 hover:scale-100 bg-red-100":
                      isCardActive && !isCardDisabled && !willCancelAtPeriodEnd,
                    "text-gray-400 hover:scale-100 bg-gray-100 cursor-not-allowed pointer-events-none":
                      isCardDisabled,
                    "text-dorothy-orange hover:scale-100 bg-dorothy-orange/30 cursor-not-allowed pointer-events-none":
                      isUpcoming,
                  }
                )}
                onClick={handleSubscription}
              >
                {buttonName}
              </button>
            )}
            {isUpcoming && (
              <button
                className="flex justify-center items-center border-0 p-3 w-full text-red-400 hover:scale-110 bg-red-100 rounded-lg text-base font-extrabold mt-2 transition ease-in-out"
                onClick={cancelUpcomingSubscription}
              >
                {packageButton.cancelUpcoming}
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        isShow={viewModal.show}
        closeHandler={closeModal}
        title="Change subscription plan"
        closeButton
      >
        <div className="flex flex-col gap-y-3">
          <div
            className="text-base"
            dangerouslySetInnerHTML={{ __html: viewModal.text }}
          />
          <div className="flex justify-end gap-x-4 items-center mt-4">
            <Button
              variant="outlined"
              className="border-gray-400 hover:bg-gray-50 text-gray-400"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              className="bg-dark-purple px-8"
              onClick={() => {}}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionCard;
