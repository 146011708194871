import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Snackbar } from "../../components/shared";
import { useCustomerContext } from "../../contexts/CustomerContext";
import toast from "react-hot-toast";
import { useCreateSubscriptionMutation } from "../../features/subscriptionsApi";
import { useNavigate } from "react-router-dom";
import { useCustomerData } from "../../hooks/useCustomerData";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { routes } from "../../constants/routes";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import SuccessModal from "../../components/shared/modal/SuccessModal";

interface CheckoutFormProps {
  storedPackage: {
    name: string;
    currency: string;
    interval: string;
    price: number;
    priceId: string;
  };
}
const CheckoutForm: React.FC<CheckoutFormProps> = ({ storedPackage }) => {
  const navigate = useNavigate();
  // const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { paymentIntent, customerId } = useCustomerContext();
  const { refetchCustomerSubscriptions, refetchScheduledSubscriptions } =
    useCustomerData();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [createSubscription] = useCreateSubscriptionMutation();

  const handlePayment = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error((t) => (
        <Snackbar
          message={<>Stripe.js or Elements has not loaded yet.</>}
          onClose={() => toast.dismiss(t.id)}
        />
      ));
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent: confirmedPaymentIntent } =
        await stripe.confirmPayment({
          elements,
          redirect: "if_required",
        });

      if (error) {
        toast.error((t) => (
          <Snackbar
            message={
              <>{error.message || "Payment failed. Please try again."}</>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      } else if (confirmedPaymentIntent?.status === "succeeded") {
        const { priceId } = storedPackage;
        await createSubscription({ customerId, priceId });
        // setShowSuccessModal(true);
        await Promise.allSettled([
          refetchScheduledSubscriptions(),
          refetchCustomerSubscriptions(),
        ]).then(() => navigate(routes.projects, { replace: true }));
      }
    } catch (err) {
      console.error("Unexpected error during payment:", err);
      toast.error((t) => (
        <Snackbar
          message={<>An unexpected error occurred. Please try again.</>}
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <>
      <form onSubmit={handlePayment} className="max-w-4xl border p-4">
        {stripe && elements && paymentIntent?.client_secret ? (
          <PaymentElement />
        ) : (
          <p>Loading payment form...</p>
        )}
        <button
          disabled={isProcessing || !stripe || !elements}
          className={`mt-5 bg-dark-purple text-white w-full flex justify-center items-center text-center h-[40px] rounded-lg font-extrabold font-inter ${
            isProcessing || !stripe || !elements
              ? "opacity-50 cursor-not-allowed hover:bg-purple"
              : "hover:bg-purple-600"
          }`}
        >
          {isProcessing || !stripe || !elements ? (
            <div className="price-loader-sm">
              <Loader />
            </div>
          ) : (
            <span className="text-lg">Pay</span>
          )}
        </button>
      </form>
      {/* <SuccessModal
        title="Payment successful!"
        isShow={showSuccessModal}
        closeHandler={() => {
          setShowSuccessModal(false);
        }}
        description="Your subscription payment has been successfully processed. Thank you for subscribing!"
        icon={<CheckBadgeIcon />}
      /> */}
    </>
  );
};

export default CheckoutForm;
