import React from "react";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/down-arrow.svg";
import { useNavigate } from "react-router-dom";
interface PaymentSummaryProps {
  name: string;
  amount: number;
  interval: string;
  isPriceLoading: boolean;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  name,
  interval,
  isPriceLoading,
  amount,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex items-center gap-x-2 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <span className="rotate-90 left-arrow bg-dark-purple/10 p-2 rounded-full">
          <LeftArrow />
        </span>
        <span className="font-extrabold text-dark-purple">Back</span>
      </div>
      <div className="flex flex-col">
        <span className="font-extrabold text-3xl mb-4">Payment Summary</span>
        <div className="text-base flex gap-x-3">
          <strong>Subscription:</strong>
          <div className="font-bold text-dark-purple">
            {name}{" "}
            <sup className="bg-dark-purple px-2 rounded-lg font-extrabold text-white text-[10px]">
              {interval}ly
            </sup>
          </div>
        </div>
        <div className="text-base flex gap-x-3">
          <strong>Amount:</strong>
          {isPriceLoading ? (
            <span className="price-loader relative bottom-3">
              <Loader />
            </span>
          ) : (
            <span className="font-bold text-dark-purple">${amount}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSummary;
