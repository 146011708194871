import { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ProjectCard from "../components/projectcard/ProjectCard";
import {
  Button,
  EmptyState,
  Loader,
  Modal,
  Snackbar,
  Unauthorized,
} from "../components/shared";
import {
  IProject,
  useDeleteProjectMutation,
  useGetProjectsQuery,
} from "../features/projectsApi";
import { toast } from "react-hot-toast";

const REFRESH_TIME_SEC: any =
  process.env.REACT_APP_INQUEUE_REFRESH_TIME_SEC || 3000;

let refreshTimeoutForProjects: any = null;

interface ProjectsProps {
  isSubscriptionActive: boolean;
}

const Projects: React.FC<ProjectsProps> = ({ isSubscriptionActive }) => {
  const navigation = useNavigate();
  const [deleteProject] = useDeleteProjectMutation();
  const [confirmModalId, setConfirmModalId] = useState<any>(null);

  const {
    data: projects,
    isLoading,
    isError,
    error,
    refetch,
    fulfilledTimeStamp,
  } = useGetProjectsQuery(
    {
      currentPage: 1,
      pageSize: 100,
    },
    { refetchOnMountOrArgChange: true }
  );

  const detail = (projectId: any) => (e: any) => {
    navigation(projectId);
  };

  const deleteHandler = () => {
    deleteProject(confirmModalId).then((response: any) => {
      if ("data" in response) {
        toast.success((t) => (
          <Snackbar
            message={
              <>
                Project <b>deleted.</b>
              </>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      } else {
        toast.error((t) => (
          <Snackbar
            message={
              <>
                <b>Failed</b> to delete project.
              </>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      }
    });
    setConfirmModalId(null);
  };

  useEffect(() => {
    clearTimeout(refreshTimeoutForProjects);
    if (projects && projects.rows.length > 0) {
      let refetchRequired = false;
      projects.rows.forEach((projectRow: any, indis: number) => {
        if (projectRow.queueCounts?.inQueue > 0) {
          refetchRequired = true;
        }
      });
      if (refetchRequired) {
        refreshTimeoutForProjects = setTimeout(() => {
          refetch();
        }, parseInt(REFRESH_TIME_SEC) * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilledTimeStamp]);

  useEffect(() => {
    return () => {
      clearTimeout(refreshTimeoutForProjects);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-between mb-0 md:flex-row">
        <div className="mb-4 md:mb-0">
          <h2 className="text-2xl font-sweetsanspro font-semibold">Projects</h2>
        </div>
        <div className="full-button">
          <Button
            onClick={() => navigation("new")}
            disabled={!isSubscriptionActive}
          >
            Create Project
          </Button>
          &nbsp;
        </div>
      </div>
      {projects && projects.count > 0 ? (
        <>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-col-5">
            {projects?.rows.map((item: IProject) => {
              return (
                <div key={item.id}>
                  <ProjectCard
                    onClick={detail(item.id)}
                    isSubscriptionActive={isSubscriptionActive}
                    data={item}
                    onDelete={() => setConfirmModalId(item.id)}
                  />
                </div>
              );
            })}
          </div>
          <Modal
            type="simple"
            isShow={confirmModalId ? true : false}
            closeHandler={() => setConfirmModalId(null)}
          >
            <div className="p-7">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                  <ExclamationCircleIcon
                    className="h-12 w-12 text-error"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Delete Project
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure want to delete this project?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <Button
                  variant="outlined"
                  onClick={() => setConfirmModalId(null)}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={deleteHandler}>Delete</Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <EmptyState
          label="No Projects"
          description="Create your first project to get started."
        />
      )}
    </div>
  );
};

export default Projects;
