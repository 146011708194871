import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../layout/Main";
import Projects from "../pages/Projects";
import ProjectDetail from "../pages/ProjectDetail";
import Result from "../pages/Result";
import Help from "../pages/Help";
import Logout from "../pages/Logout";
import Error from "../pages/Error";
import NewProject from "../pages/NewProject";
import EditProject from "../pages/EditProject";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import PeopleAI from "../pages/peopleai/PeopleAI";
import CreateProject from "../pages/peopleai/CreateProject";
import CrossCite from "../pages/CrossCite";
import ViewProject from "../pages/peopleai/ViewProject";
import Payment from "../pages/subscriptions/Payment";
import Subscriptions from "../pages/subscriptions/Subscriptions";
import { useCustomerData } from "../hooks/useCustomerData";
import { routes } from "../constants/routes";
import { subscriptionStatuses } from "../constants/subscriptions";
import { comboPackagesList } from "../constants/packages";

const AppRoutes = () => {
  const { activeSubscription } = useCustomerData();
  const isSubscriptionActive =
    activeSubscription &&
    activeSubscription.status === subscriptionStatuses.active;
  const isPeopleAIActive =
    activeSubscription?.items?.[0]?.product?.name ===
    comboPackagesList.ultimate;
  const isCrossCiteActive =
    activeSubscription?.items?.[0]?.product?.name ===
      comboPackagesList.ultimate ||
    activeSubscription?.items?.[0]?.product?.name === comboPackagesList.premium;
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute isSubscriptionActive={true} />}>
        <Route path="/" element={<Main />}>
          <Route
            index
            element={
              <Navigate
                to={
                  isSubscriptionActive ? routes.projects : routes.subscriptions
                }
              />
            }
          />

          <Route path="subscriptions">
            <Route index element={<Subscriptions />} />
            <Route path="pay" element={<Payment />} />
          </Route>

          <Route path="projects">
            <Route
              index
              element={<Projects isSubscriptionActive={isSubscriptionActive} />}
            />
            <Route
              path=":projectId"
              element={
                <ProjectDetail isSubscriptionActive={isSubscriptionActive} />
              }
            />
            <Route
              element={
                <ProtectedRoute isSubscriptionActive={isSubscriptionActive} />
              }
            >
              <Route path="new" element={<NewProject />} />
              <Route path="edit/:projectId" element={<EditProject />} />
              <Route path=":projectId/:result/:searchId" element={<Result />} />
            </Route>
          </Route>

          <Route
            path="crosscite"
            element={
              <ProtectedRoute isSubscriptionActive={isCrossCiteActive} />
            }
          >
            <Route index element={<CrossCite />} />
          </Route>

          <Route
            path="peopleai"
            element={<ProtectedRoute isSubscriptionActive={isPeopleAIActive} />}
          >
            <Route index element={<PeopleAI />} />
            <Route path="create" element={<CreateProject />} />
            <Route path="view/:id" element={<ViewProject />} />
          </Route>

          <Route path="help" element={<Help />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
        </Route>
      </Route>
      <Route path="logout" element={<Logout />} />
      <Route
        path="*"
        element={<Error description="Message: Page not found." />}
      />
    </Routes>
  );
};

export default AppRoutes;
