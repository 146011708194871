import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loader } from "../components/shared";

interface ProtectedRouteProps {
  isSubscriptionActive: boolean;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isSubscriptionActive,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        setIsLoading(false);
      }
    }
  }, [initialized, keycloak, location]);

  if (isLoading) {
    return <Loader />;
  }
  return isSubscriptionActive ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
